import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import * as XLSX from 'xlsx';

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";


import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";

import { CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@material-ui/core";
import { UsersFilter } from "../../components/UsersFilter";
import { WhatsappsFilter } from "../../components/WhatsappsFilter";
import { StatusFilter } from "../../components/StatusFilter";
import useDashboard from "../../hooks/useDashboard";

import QueueSelect from "../../components/QueueSelect";
import moment from "moment";

import { green } from "@material-ui/core/colors";
import { Forward, SaveAlt } from "@material-ui/icons";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: theme.palette.fancyBackground,

  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainPaper: {
    flex: 1,
    marginTop: 40,
    borderRadius: 20,
    border: '0px !important',
    marginBottom: 40,
    overflow: 'hidden'
  },
  mainPaperTable: {
    flex: 1,
    overflow: 'auto',
    height: '68vh',
    ...theme.scrollbarStylesSoftBig,
  },
  mainPaperFilter: {
    flex: 1,
    // overflow: 'auto',
    height: '20vh',
    ...theme.scrollbarStylesSoftBig,
  },
  mainHeaderBlock: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexWrap: 'wrap'
    },
  },
  filterItem: {
    width: 200,
    [theme.breakpoints.down('md')]: {
      width: '45%'
    },
  },
}));

const Reports = () => {
  const classes = useStyles();
  const history = useHistory();
  const { getReport } = useDashboard();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Defina o tamanho da página

  const [searchParam, setSearchParam] = useState("");
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const [queueIds, setQueueIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [options, setOptions] = useState([]);
  const [dateFrom, setDateFrom] = useState(moment("1", "D").format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [totalTickets, setTotalTickets] = useState(0);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("contacts", {
            params: { searchParam },
          });
          setOptions(data.contacts);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam]);

  // const handleSelectedTags = (selecteds) => {
  //   const tags = selecteds.map((t) => t.id);
  //   setTagIds(tags);
  // };

  const exportarGridParaExcel = async () => {
    setLoading(true); // Define o estado de loading como true durante o carregamento



    try {
      const data = await getReport({
        searchParam,
        contactId: selectedContactId,
        whatsappId: JSON.stringify(selectedWhatsapp),
        // tags: JSON.stringify(tagIds),
        users: JSON.stringify(userIds),
        queueIds: JSON.stringify(queueIds),
        status: JSON.stringify(selectedStatus),
        // tags: tagIds,
        dateFrom,
        dateTo,
        page: 1, // Passa o número da página para a API
        pageSize: 9999999, // Passa o tamanho da página para a API
      });

      const ticketsData = data.tickets.map(ticket => {

        return {
          id: ticket.id,
          Conexão: ticket.whatsappName,
          Contato: ticket.contactName,
          Usuário: ticket.userName,
          Fila: ticket.queueName,
          Status: ticket.status,
          ÚltimaMensagem: ticket.lastMessage,
          DataHoraAbertura: ticket.createdAt,
          DataHoraFechamento: ticket.closedAt === null ? "" : ticket.closedAt,
          TempoDeAtendimento: ticket.supportTime,
          nps: ticket.NPS,
        }
      });

      const ws = XLSX.utils.json_to_sheet(ticketsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'RelatorioDeAtendimentos');
      XLSX.writeFile(wb, 'relatorio-de-atendimentos.xlsx');

      setPageNumber(pageNumber); // Atualiza o estado da página atual
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false); // Define o estado de loading como false após o carregamento
    }

  };


  const handleFilter = async (pageNumber) => {
    setLoading(true); // Define o estado de loading como true durante o carregamento

    try {
      const data = await getReport({
        searchParam,
        contactId: selectedContactId,
        whatsappId: JSON.stringify(selectedWhatsapp),
        // tags: JSON.stringify(tagIds),
        users: JSON.stringify(userIds),
        queueIds: JSON.stringify(queueIds),
        status: JSON.stringify(selectedStatus),
        // tags: tagIds,
        dateFrom,
        dateTo,
        page: pageNumber, // Passa o número da página para a API
        pageSize: pageSize, // Passa o tamanho da página para a API
      });

      setTotalTickets(data.totalTickets.total);

      setTickets(data.tickets); // Se for a primeira página, substitua os tickets

      setPageNumber(pageNumber); // Atualiza o estado da página atual
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false); // Define o estado de loading como false após o carregamento
    }
  }

  const handleSelectedUsers = (selecteds) => {
    const users = selecteds.map((t) => t.id);
    setUserIds(users);
  };

  const handleSelectedWhatsapps = (selecteds) => {
    const whatsapp = selecteds.map((t) => t.id);
    setSelectedWhatsapp(whatsapp);
  };

  const handleSelectedStatus = (selecteds) => {
    const statusFilter = selecteds.map((t) => t.status);

    setSelectedStatus(statusFilter);
  };

  const renderOption = (option) => {
    if (option.number) {
      return <>
        <Typography component="span" style={{ fontSize: 14, marginLeft: "10px", display: "inline-flex", alignItems: "center", lineHeight: "2" }}>
          {option.name} - {option.number}
        </Typography>
      </>
    } else {
      return `${i18n.t("newTicketModal.add")} ${option.name}`;
    }
  };

  const handleSelectOption = (event, newValue) => {
    if (!newValue) {
      setSelectedContactId(null);  // Limpa o contato selecionado
      setSearchParam("");
    } else {
      setSelectedContactId(newValue.id);
      setSearchParam("");
    }
  };

  const renderOptionLabel = option => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${option.name}`;
    }
  };
  const filter = createFilterOptions({
    trim: true,
  });

  const createAddContactOption = (filterOptions, params) => {
    const filtered = filter(filterOptions, params);
    if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
      filtered.push({
        name: `${params.inputValue}`,
      });
    }
    return filtered;
  };

  const renderContactAutocomplete = () => {
    return (
        <Grid xs={12} item>
          <Autocomplete
              fullWidth
              options={options}
              loading={loading}
              clearOnBlur
              autoHighlight
              freeSolo
              size="small"
              clearOnEscape
              getOptionLabel={renderOptionLabel}
              renderOption={renderOption}
              filterOptions={createAddContactOption}
              onChange={(e, newValue) => handleSelectOption(e, newValue)}
              onInputChange={(event, newInputValue) => {
                if (newInputValue === "") {
                  // Limpar o estado ao remover a seleção
                  handleSelectOption(event, null);
                }
              }}
              renderInput={params => (
                  <TextField
                      {...params}
                      label={i18n.t("newTicketModal.fieldLabel")}
                      variant="outlined"
                      autoFocus
                      size="small"
                      onChange={e => setSearchParam(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                      }}
                  />
              )}
          />
        </Grid>
    );
  };

  return (
      <MainContainer className={classes.mainContainer}>
        <Title>{i18n.t("reports.title")}</Title>

        <MainHeader className={classes.mainHeaderFilter} style={{ display: 'flex' }}>
          <Paper className={classes.mainPaperFilter}>
            <div style={{ paddingTop: '15px' }} />
            <Grid container spacing={1}>
              <Grid item xs={12} md={3} xl={3}>
                {renderContactAutocomplete()}
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <WhatsappsFilter onFiltered={handleSelectedWhatsapps} />
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <StatusFilter onFiltered={handleSelectedStatus} />
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <UsersFilter onFiltered={handleSelectedUsers} />
              </Grid>
              {/* <Grid item xs={12} md={4} xl={4}>
              <TagsFilter onFiltered={handleSelectedTags} />
            </Grid> */}
              <Grid item xs={12} md={3} xl={3} style={{ marginTop: '-13px' }}>
                <QueueSelect
                    selectedQueueIds={queueIds}
                    onChange={values => setQueueIds(values)}
                />
              </Grid>

              <Grid item xs={12} sm={3} md={3}>
                <TextField
                    label="Data Inicial"
                    type="date"
                    value={dateFrom}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={(e) => setDateFrom(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <TextField
                    label="Data Final"
                    type="date"
                    value={dateTo}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={(e) => setDateTo(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton onClick={exportarGridParaExcel} aria-label="Exportar para Excel">
                  <SaveAlt />
                </IconButton>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleFilter(pageNumber)}
                    size="small"
                >{i18n.t("reports.buttons.filter")}</Button>
              </Grid>
            </Grid>

          </Paper>

        </MainHeader>
        <Paper
            className={classes.mainPaperTable}
            variant="outlined"
        >
          <Table size="small" id="grid-attendants">
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox" /> */}
                <TableCell align="center">{i18n.t("reports.table.id")}</TableCell>
                <TableCell align="left">{i18n.t("reports.table.whatsapp")}</TableCell>
                <TableCell align="left">{i18n.t("reports.table.contact")}</TableCell>
                <TableCell align="left">{i18n.t("reports.table.user")}</TableCell>
                <TableCell align="left">{i18n.t("reports.table.queue")}</TableCell>
                <TableCell align="center">{i18n.t("reports.table.status")}</TableCell>
                <TableCell align="left">{i18n.t("reports.table.lastMessage")}</TableCell>
                <TableCell align="center">{i18n.t("reports.table.dateOpen")}</TableCell>
                <TableCell align="center">{i18n.t("reports.table.dateClose")}</TableCell>
                <TableCell align="center">{i18n.t("reports.table.supportTime")}</TableCell>
                <TableCell align="center">{i18n.t("reports.table.NPS")}</TableCell>
                <TableCell align="center">{i18n.t("reports.table.actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {tickets.map((ticket) => (
                    <TableRow key={ticket.id}>
                      <TableCell align="center">{ticket.id}</TableCell>
                      <TableCell align="left">{ticket?.whatsappName}</TableCell>
                      <TableCell align="left">{ticket?.contactName}</TableCell>
                      <TableCell align="left">{ticket?.userName}</TableCell>
                      <TableCell align="left">{ticket?.queueName}</TableCell>
                      <TableCell align="center">{ticket?.status}</TableCell>
                      <TableCell align="left">{ticket?.lastMessage}</TableCell>
                      <TableCell align="center">{ticket?.createdAt}</TableCell>
                      <TableCell align="center">{ticket?.closedAt}</TableCell>
                      <TableCell align="center">{ticket?.supportTime}</TableCell>
                      <TableCell align="center">{ticket?.NPS}</TableCell>
                      <TableCell align="center">
                        <Typography
                            noWrap
                            component="span"
                            variant="body2"
                            color="textPrimary"
                        >
                          <Tooltip title="Acessar Ticket">
                            <Forward
                                onClick={() => { history.push(`/tickets/${ticket.uuid}`) }}
                                fontSize="small"
                                style={{
                                  color: green[700],
                                  cursor: "pointer",
                                  marginLeft: 10,
                                  verticalAlign: "middle"
                                }}
                            />
                          </Tooltip>
                        </Typography>
                      </TableCell>
                    </TableRow>
                ))}
                {loading && <TableRowSkeleton avatar columns={3} />}
              </>
            </TableBody>
          </Table>

        </Paper>

        <div>
          <Grid container>
            <Grid item xs={12} sm={10} md={10}>

              <Pagination
                  count={Math.ceil(totalTickets / pageSize)} // Calcula o nmero total de páginas com base no nmero total de tickets e no tamanho da página
                  page={pageNumber} // Define a página atual
                  onChange={(event, value) => handleFilter(value)} // Função de callback para mudanças de página
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>

              <FormControl
                  margin="dense"
                  variant="outlined"
                  fullWidth
              >
                <InputLabel>
                  {i18n.t("tickets.search.ticketsPerPage")}
                </InputLabel>
                <Select
                    labelId="dialog-select-prompt-label"
                    id="dialog-select-prompt"
                    name="pageSize"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(e.target.value)
                    }}
                    label={i18n.t("tickets.search.ticketsPerPage")}
                    fullWidth
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "center",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "center",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                >
                  <MenuItem value={5} >{"5"}</MenuItem>
                  <MenuItem value={10} >{"10"}</MenuItem>
                  <MenuItem value={20} >{"20"}</MenuItem>
                  <MenuItem value={50} >{"50"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </MainContainer >
  );
};

export default Reports;
