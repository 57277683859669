import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  button: {
    background: "#10a110",
    border: "none",
    padding: "10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
  },
  
}));

const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();


  const [tags, setTags] = useState([]);
  const [reloadData, setReloadData] = useState(false);

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.lista || []; 

      setTags(fetchedTags);

      // Fetch tickets after fetching tags
      await fetchTickets(jsonString);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const [file, setFile] = useState({
    lanes: []
  });



  const [tickets, setTickets] = useState([]);
  const { user } = useContext(AuthContext);
  const jsonString = user.queues.map(queue => queue.UserQueue.queueId);
  

  
 

  const fetchTickets = async (jsonString) => {
    try {
      
      const { data } = await api.get("/ticket/kanban", {
        params: {
          queueIds: JSON.stringify(jsonString),
          teste: true
        }
      });
      setTickets(data.tickets);
    } catch (err) {
      console.log(err);
      setTickets([]);
    }
  };


  const popularCards = (jsonString) => {
    const filteredTickets = tickets.filter(ticket => ticket.tags.length === 0);
    const lanes = tags.map((tag) => {
      const filteredTickets = tickets.filter((ticket) =>
        ticket.tags.some((ticketTag) => ticketTag.id === tag.id)
      );

      return {
        id: tag.id.toString(),
        title: tag.name,
        label: filteredTickets.length.toString(),
        cards: filteredTickets.map((ticket) => ({
          id: ticket.id.toString(),
          title: ticket.contact.name,
          label: `Ticket nº ${ticket.id}`,
          description: (
            <div>
              <p>
                {ticket.contact.number}
                <br />
                {ticket.lastMessage}
              </p>
              <button
                className={classes.button}
                onClick={() => handleCardClick(ticket.uuid)}
              >
                Ver Ticket
              </button>
            </div>
          ),
          draggable: true,
        })),
        style: { backgroundColor: tag.color, color: "white" },
      };
    });

    if (tags.length === 0) {
      toast.warn("Nenhuma tag cadastrada no CRM");
    } else {
      setFile({ lanes });
    }
  };

  const handleCardClick = (uuid) => {  
    //console.log("Clicked on card with UUID:", uuid);
    history.push('/tickets/' + uuid);
  };

  useEffect(() => {
    popularCards(jsonString);
}, [tags, tickets, reloadData]);

  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    try {
        
          await api.delete(`/ticket-tags/${targetLaneId}`);
        toast.success('Ticket Tag Removido!');
          await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}`);
        toast.success('Ticket Tag Adicionado com Sucesso!');

    } catch (err) {
      console.log(err);
    }
  };

  const handleCardDelete = async (cardId, sourceLaneId, targetLaneId) => {
    try {
        
          await api.delete(`/ticket-tags/${targetLaneId}`);
        toast.success('Ticket Tag Removido!');
         

    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
  <Board 
  data={file} 
  onCardMoveAcrossLanes={handleCardMove}
  onLaneDelete={handleCardDelete}
  onCardDelete = {handleCardDelete}  
  style={{ backgroundColor: 'rgba(252, 252, 252, 0.03)' }}
  //hideCardDeleteIcon={laneId => laneId === "lane0"} // Oculta "X" na lane "Em aberto"
/>
    </div>
  );
};


export default Kanban;
