import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Chip, Tooltip,  Box } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsListCustom";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Button } from "@material-ui/core";
import { TagsFilter } from "../TagsFilter";
import { UsersFilter } from "../UsersFilter";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import {  Collapse } from "@material-ui/core";
import {  ExpandMore as ExpandIcon, ExpandLess as CollapseIcon } from "@material-ui/icons";



const useStyles = makeStyles(theme => ({

  conversasContainer: {
    //width: 350,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    position: "relative",
  
    //borderRadius: theme.shape.borderRadius,
    //backgroundColor: "#f4f5f7",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  headerTitle: {
    fontSize: 16,
    marginLeft:36,
    
    margin: 0,
  },
  addButton: {
    backgroundColor: "#25D366", // Cor verde do WhatsApp
    color: "white",
    padding: theme.spacing(1),
    borderRadius: "50%",
    fontSize: 5,
    "&:hover": {
      backgroundColor: "#128C7E", // Verde mais escuro para o hover
    },
  },
  searchBar: {
 

    background: theme.palette.tabHeaderBackground,
    flex: 1,
	
		display: "flex",
		borderRadius: 50,
		padding: 4,
		marginRight: theme.spacing(1),
  },
  searchIcon: {
    color: "#888",
    marginRight: theme.spacing(1),
  },
  searchInput: {
    flex: 1,
    fontSize:10,
  },
  menuButton: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  conversationsList: {
    flex: 1,
    overflowY: "auto",
  },
  selectContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  customSelect: {
    fontSize: 14,
    marginTop:  theme.spacing(2),
    //fontWeight: 500,
    //color: "#333",
    padding: 0,
    paddingRight: theme.spacing(1),
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      paddingRight: theme.spacing(2),
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 14,
      //fontWeight: 500,
      lineHeight: 1.5,
    },
    "& .MuiSelect-icon": {
      right: 0,
    // Alinha o ícone à direita
      //color: "#333", // Cor do ícone para combinar com o texto
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-root": {
      backgroundColor: "transparent !important",
    },
  },
  menuItem: {
    fontSize: 14,
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chip: {
    marginLeft: 8,
    height: 20, // Define a altura do círculo
    width: 20,  // Define a largura do círculo
    borderRadius: "50%", // Torna o Box redondo
    backgroundColor: "#25D366",
    color: "#fff",
    fontSize: "0.75rem",
    display: "flex",
    alignItems: "center", // Centraliza o conteúdo verticalmente
    justifyContent: "center", // Centraliza o conteúdo horizontalmente
    lineHeight: 1, // Ajusta a altura da linha para centralização precisa
  },

  
	ticketsWrapper: {
		position: "relative",
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflow: "hidden",
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		borderRadius:0,
	},


	tabsHeader: {
		flex: "none",
		backgroundColor: theme.palette.optionsBackground,
	},

	tabsInternal: {
		flex: "none",
		backgroundColor: theme.palette.optionsBackground
	},

	settingsIcon: {
		alignSelf: "center",
		marginLeft: "auto",
		padding: 8,
	},

	tab: {
		minWidth: 120,
		width: 120,
	},

	internalTab: {
		minWidth: 120,
		width: 120,
		padding: 5
	},

	ticketOptionsBox: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		background: theme.palette.optionsBackground,
		padding: theme.spacing(1),
	},

	ticketSearchLine: {
		padding: theme.spacing(1),
	},

	serachInputWrapper: {
		flex: 1,
		background: theme.palette.total,
		display: "flex",
		borderRadius: 40,
		padding: 4,
		marginRight: theme.spacing(1),
	},

	searchIcon: {
		color: "grey",
		marginLeft: 6,
		marginRight: 6,
		alignSelf: "center",
	},

	searchInput: {
		flex: 1,
		border: "none",
		//borderRadius: 30,
	},

	insiderTabPanel: {
		height: '100%',
		marginTop: "-72px",
		paddingTop: "72px"
	},

	insiderDoubleTabPanel: {
		display:"flex",
		flexDirection: "column",
		marginTop: "-72px",
		paddingTop: "72px",
		height: "100%"
	},

	labelContainer: {
		width: "auto",
		padding: 0
	},
	iconLabelWrapper: {
		flexDirection: "row",
		'& > *:first-child': {
			marginBottom: '3px !important',
			marginRight: 16
		}
	},
	insiderTabLabel: {
		[theme.breakpoints.down(1600)]: {
			display:'none'
		}
	},
	smallFormControl: {
		'& .MuiOutlinedInput-input': {
			padding: "12px 10px",
		},
		'& .MuiInputLabel-outlined': {
			marginTop: "-6px"
		}
	}
}));

const TicketsManagerTabs = () => {
  const classes = useStyles();
  const history = useHistory();

  const [searchParam, setSearchParam] = useState("");
  
  const [pauseSearch, setpauseSearch] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const { profile } = user;

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [messageFilter, setMessageFilter] = useState("open");

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


 
    const [expanded, setExpanded] = useState(true);  // Controla a expansão do conteúdo
  
    // Função para alternar a expansão e chamar um código específico ao recolher
    const handleExpandToggle = () => {
      if (expanded) {
        // Coloque aqui o código que deve ser executado ao recolher
        setSearchParam("");
        setSelectedUsers("");
        setSelectedTags(""); 

      }
      setExpanded(!expanded);
    };
  

  const handleVerify = () => {
    
    if (selectedTags.length || selectedUsers.length || (searchParam && searchParam !== "")) {
      setSearchText(true);
      setTab("search");
    } else {
      setTab("open");
      setSearchText(""); 
    }
  };
  
  // useEffect para monitorar mudanças em todos os estados relevantes
  useEffect(() => {
    handleVerify();
  }, [selectedTags, selectedUsers, selectedQueueIds, searchParam]);
  

  const [filtersVisible, setFiltersVisible] = useState(false);

  const handleMenuToggle = () => {
    setFiltersVisible((prev) => !prev);
  };

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();
    
    // Atualiza o termo de busca imediatamente a cada tecla digitada
    if (searchedTerm === "") {
      // Força o reset de searchParam mesmo que já esteja como "unknow"
      setSearchParam((prev) => prev !== "unknow" ? "unknow" : "");
      setTimeout(() => setSearchParam("unknow"), 0); // Força a atualização
    } else {
      setSearchParam(searchedTerm);
    }
  };

  const [searchText, setSearchText] = useState("");
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      
      const searchedTerm = e.target.value.toLowerCase();
      
        // Atualiza o termo de busca imediatamente a cada tecla digitada
        if (searchedTerm === "") {
        
        
          setSearchParam("");
         
          
        }
    else
    {
      
           
      setSearchParam(searchedTerm);
    
      
    }
  
    }
  };
  

  const handleSearchChange = (event) => {
    const value = event.target.value;
    
    
    if (value && value !=="") {
      setTab("search");
      const searchedTerm = event.target.value.toLowerCase();
    
     
      setSearchParam(searchedTerm);
    
       // Muda para a aba "search" ao digitar
    } else {
      
      setSearchParam("");     
    
       // Volta para a aba "open" se o campo de busca estiver vazio
    }
  };

  const handleMenuSelect = (event) => {
    const selectedOption = event.target.value;
    if (selectedOption === "open") {
      setTab("open"); // Seleciona a aba "open"
    } else if (selectedOption === "closed") {
      setTab("closed"); // Seleciona a aba "closed"
    }
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  
  const [ignoreSocket, setIgnoreSocket] = useState(false);  // Define como booleano inicial


  const handleFilterChange = (event) => {
    setMessageFilter(event.target.value);
  };

  
  const handleSelectedTags = (selecteds) => {
  
      
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
   
  
    
   
  };
  const handleSelectFila = (values) =>
    { 
      
     
      setSelectedQueueIds(values)
    
     
      
  
    }


  const handleSelectedUsers = (selecteds) => {
      
    const users = selecteds.map((t) => t.id);
    setSelectedUsers(users);   
   
      
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(ticket) => {
          
          handleCloseOrOpenTicket(ticket);
        }}
      />
        <Paper className={classes.conversasContainer} elevation={0}>
        <div className={classes.header}>
          <h5 className={classes.headerTitle}>Conversas</h5>
          <Tooltip arrow title="Criar novo atendimento.">
            <IconButton className={classes.addButton} onClick={() => setNewTicketModalOpen(true)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
        
        <Collapse in={expanded}>  {/* Componente Collapse para expandir/recolher o conteúdo */}
          <div className={classes.searchBar}>
            <SearchIcon className={classes.searchIcon} />
            <InputBase onChange={handleSearchChange} onKeyDown={handleKeyDown} placeholder="Pesquisar" className={classes.searchInput} />
            <IconButton className={classes.menuButton} onClick={handleMenuToggle}>
              <MoreVertIcon />
            </IconButton>
          </div>

          {filtersVisible && (
            <div className={classes.filtersContainer}>
              <TagsFilter onFiltered={handleSelectedTags} />
              {profile === "admin" && <UsersFilter onFiltered={handleSelectedUsers} />}
              <TicketsQueueSelect
                selectedQueueIds={selectedQueueIds}
                userQueues={user?.queues}
                onChange={(values) => setSelectedQueueIds(values)}
              />
              <Box style={{ padding: 10 }}>
                <Can
                  role={user.profile}
                  perform="tickets-manager:showall"
                  yes={() => (
                    <FormControlLabel
                      label="Ver apenas grupos"
                      labelPlacement="start"
                      control={
                        <Switch
                          size="small"
                          checked={showAllTickets}
                          onChange={() => setShowAllTickets((prevState) => !prevState)}
                          name="showAllTickets"
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Box>
            </div>
          )}

          <div className={classes.selectContainer}>
            <Select
              displayEmpty
              variant="outlined"
              value={searchText ? "search" : tab === "open" ? "open" : "closed"}
              onChange={handleMenuSelect}
              disableUnderline
              className={clsx(classes.customSelect)}
            >
              <MenuItem value="open" className={classes.menuItem}>
                Tickets em atendimento.
              </MenuItem>
              <MenuItem value="closed" className={classes.menuItem}>
                Tickets fechados.
              </MenuItem>
              {searchText && (
                <MenuItem value="search" className={classes.menuItem}>
                  Resultado da busca:
                </MenuItem>
              )}
            </Select>
          </div>
        </Collapse>

        {/* Ícone para expandir/recolher no canto inferior direito */}
        <Box position="absolute" top={12} left={7}>
          <IconButton onClick={handleExpandToggle}>
            {expanded ? <CollapseIcon /> : <ExpandIcon />}
          </IconButton>
        </Box>
      </Paper>
      
 
      
      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
        label={
          <div className={classes.chipContainer}>
            <span>{i18n.t("ticketsList.assignedHeader")}</span>
            {openCount >= 1 && (
              <Box className={classes.chip}>
                {openCount}
              </Box>
            )}
          </div>
        }
        value={"open"}
      />
      <Tab
        label={
          <div className={classes.chipContainer}>
            <span>{i18n.t("ticketsList.pendingHeader")}</span>
            {pendingCount >= 1 && (
              <Box className={classes.chip}>
                {pendingCount}
              </Box>
            )}
          </div>
        }
        value={"pending"}
      />
        </Tabs>
        <Paper className={classes.ticketsWrapper}>
          <TicketsList
            status="open"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setOpenCount(val)}
            style={applyPanelStyle("open")}
          />
          <TicketsList
            status="pending"
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setPendingCount(val)}
            style={applyPanelStyle("pending")}
          />
        </Paper>
      </TabPanel>
      <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
        <TicketsList
          status="closed"
          showAll={true}
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>
      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
         
         <TicketsList
          searchParam={searchParam}
          showAll={true}
          tags={selectedTags}
          users={selectedUsers}
         
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>
    </Paper>
  );
};

export default TicketsManagerTabs;
